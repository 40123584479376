<template>
  <CalculatorUser
      :createData="createData"
      :calculator="calculator"
      :countries="countries"
      :countriesUK="countriesUK"
      :states="states"
      :sortParams="sortParams"
      :calcData="calcData"
      :easyCalcData="easyCalcData"
      @shippingCostCalculate="shippingCostCalculate"
      @changeSort="changeSort"
  />
<!--      :fromPoland="fromPoland"-->
<!--      :fromPolandToEu="fromPolandToEu"-->
<!--      :checkPayPalResult="checkPayPalResult"-->
<!--  :results="results"-->
</template>

<script>
  import CalculatorUser from "./CalculatorUser/CalculatorUser";
  import {Calculator} from "../../../../globalModels/Calculator";
  import {
    // FEDEX_DELIVERY_SERVICE,
    USA_COUNTRY_ID,
    POLAND_COUNTRY_ID,
    // SDT_COUNTRY_GROUP_ID,
    UNITED_KINGDOM_ID,
    // DHL_DELIVERY_SERVICE
  } from "../../../../../staticData/staticVariables";

  export default {
    name: "Calculator",

    components: {
      CalculatorUser,
    },

    created() {
      this.initCalc()
    },

    computed: {
      language: function () {
        return this.$store.getters.GET_LANG
      },
    },

    data() {
      return {
        createData: null,
        calculator: new Calculator(),
        countries: [],
        countriesUK: [],
        states: [],
        results: {
          'consolidation': [],
          'express': [],
          'easypost': []
        },

        calcData: null,

        easyCalcData: null,

        checkPayPalResult: false,
        fromPolandToEu: false,
        fromPoland: false,

        USA_COUNTRY_ID: USA_COUNTRY_ID,
        POLAND_COUNTRY_ID: POLAND_COUNTRY_ID,

        sortParams: {
          shipping: '',
          delivery: '',
        },
      }
    },

    methods: {

      changeSort(data) {
        this.sortParams = {
          shipping: '',
          delivery: '',
        }
        this.sortParams[data.name] = data.type

        if(this.sortParams.shipping !== '') {
          if(this.sortParams.shipping === '-') {
            this.calcData = this._.sortBy(this.calcData, 'sort_price')
          }
          if(this.sortParams.shipping === '+') {
            this.calcData = this._.sortBy(this.calcData, 'sort_price').reverse()
          }
        }

        if(this.sortParams.delivery !== '') {
          if(this.sortParams.delivery === '+') {
            this.calcData = this._.sortBy(this.calcData, 'delivery_date')
          }
          if(this.sortParams.delivery === '-') {
            this.calcData = this._.sortBy(this.calcData, 'delivery_date').reverse()
          }
        }

      },

      initCalc() {
        this.$store.dispatch('getShippingCostCalcCreate').then(response => {
          this.createData = this.getRespData(response)

          //get states data
          let statesData = this.createData?.states[Object.keys(this.createData?.states).find(Boolean)]

          for (const [key, value] of Object.entries(statesData)) {
            this.states.push({
              'key': key,
              'value': value
            })
          }

          // console.log('calc init', this.states);
        }).catch(error => this.createErrorLog(error))

        this.$store.dispatch('fetchCountries').then(() => {
          this.countries = this._.toArray(this.$store.getters.getCountries).sort((x,y) => {
            return x.translationStorage[this.$store.getters.GET_LANG]?.name.localeCompare(y.translationStorage[this.$store.getters.GET_LANG]?.name, this.$store.getters.GET_LANG)
          })
          this.countriesUK = this._.toArray(this.$store.getters.getCountries).filter(item => {
            return item.id === UNITED_KINGDOM_ID
          })
          //sort countries list to place United States first in array and keep other countries unchanged
          this.countries = this.countries.sort(function(x,y){ return x.id === USA_COUNTRY_ID ? -1 : y.id === USA_COUNTRY_ID ? 1 : 0; })

          this.$watch('language', (newVal) => {
            this.countries = this.countries.sort((x,y) => {
              return x.translationStorage[newVal]?.name.localeCompare(y.translationStorage[newVal]?.name, newVal)
            }).sort(function(x,y){ return x.id === USA_COUNTRY_ID ? -1 : y.id === USA_COUNTRY_ID ? 1 : 0; })
          })

        })

      },

      validateFranceZip() {
        if(this.calculator.getCountry()?.id === 74 && this.calculator.getPostalCode() + '' === '98000') {
          this.openNotify('error', 'common_notCountryWithZip')
          return false
        }
        return true
      },

      shippingCostCalculate() {
        if (!this.calculator.dataValidation()) return
        if (!this.validateFranceZip()) return

        this.checkPayPalResult = this.calculator.data.payPalClient

        let prepareData = {
          'sender_warehouse_id': this.calculator.getSenderWarehouseId(),
          "recipient" : {
            "country_id" : this.calculator.getCountry()?.id,
            'country_region_id': this.calculator.getCountry()?.id === USA_COUNTRY_ID ? this.calculator.getCountryRegion()?.key : null,
            "postal_code" : this.calculator.getPostalCode(),
            "city" : this.calculator.getCity()
          },
          "dimension" : {
            'weight': this.calculator.data.Dimensions.getDimensionsWeight(),
            'length': this.calculator.data.Dimensions.getDimensionsLength(),
            'width': this.calculator.data.Dimensions.getDimensionsWidth(),
            'height': this.calculator.data.Dimensions.getDimensionsHeight(),
          },
          "pay_pal" : this.calculator.getPayPalClient()
        }



        // console.log(this.calculator.data, prepareData)

        let dispatch = 'getShippingCostCalcResult'

        if(this.isUserHasEasyOrder) {
          dispatch = 'getShippingCostEasyCalcResult'
        }

        this.$store.dispatch(dispatch, prepareData).then(response => {

          this.calcData = this.getRespData(response)?.price_list

          if(this.isUserHasEasyOrder) {
            this.easyCalcData = this.getRespData(response)
          }

          // let calcData = this.getRespData(response)
          //
          // let prepareCalcData = {
          //   'consolidation': [],
          //   'express': [],
          //   'easypost': [],
          // }
          //
          // this.fromPolandToEu = false
          // this.fromPoland = false
          //
          // if (calcData?.input_data) {
          //   this.fromPoland = calcData?.input_data?.sender_country?.id === this.POLAND_COUNTRY_ID
          //   this.fromPolandToEu = calcData?.input_data?.sender_country?.id === this.POLAND_COUNTRY_ID &&
          //     (calcData?.recipient_country?.country_groups.findIndex(item => {
          //       return item.id === SDT_COUNTRY_GROUP_ID //SDT group
          //     }) !== -1 || calcData?.recipient_country?.id === UNITED_KINGDOM_ID)
          // }
          //
          // for (const [key, value] of Object.entries(calcData)) {
          //
          //   // for consolidation
          //   if (value?.consolidation?.summary) {
          //     value?.consolidation?.summary.forEach(sumItem => {
          //       prepareCalcData['consolidation'].push({
          //         'type': key,
          //         'data': sumItem
          //       })
          //     })
          //   }
          //
          //   // for NOT Fedex
          //   if (key !== FEDEX_DELIVERY_SERVICE.name && value?.express && ((value?.express?.error || value?.express?.price))) {
          //     prepareCalcData['express'].push({
          //       'type': key,
          //       'data': value?.express
          //     })
          //   }
          //
          //   // for DHL
          //   if (key === DHL_DELIVERY_SERVICE.name && value?.express) {
          //     for (const [keyFedExp, valueFedExp] of Object.entries(value.express)) {
          //       if ((valueFedExp?.error || valueFedExp?.price)) {
          //         prepareCalcData['express'].push({
          //           'type': key,
          //           'typePrice': keyFedExp,
          //           'data': valueFedExp
          //         })
          //       }
          //     }
          //   }
          //
          //   // for Fedex
          //   if (key === FEDEX_DELIVERY_SERVICE.name && value?.express) {
          //     for (const [keyFedExp, valueFedExp] of Object.entries(value.express)) {
          //       if ((valueFedExp?.error || valueFedExp?.price)) {
          //         prepareCalcData['express'].push({
          //           'type': key,
          //           'packaging': keyFedExp,
          //           'data': valueFedExp
          //         })
          //       }
          //     }
          //   }
          //
          //   // for Easypost
          //   if (key === 'easy_post' && value?.list) {
          //     value?.list.forEach(sumItem => {
          //       prepareCalcData['easypost'].push({
          //         'type': key,
          //         'data': sumItem
          //       })
          //     })
          //   }
          // }
          //
          // this.results = prepareCalcData

          // console.log('calc test', this.results);
        }).catch(error => this.createErrorLog(error))
      }
    }

  }
</script>

<style scoped>

</style>